import themeLayout from './modules/themeLayout/actionCreator';
import { createStore } from 'vuex';
import { auth } from './auth'
import { template } from './fetch'

export default createStore({
  state: {
    error: null
  },
  mutations: {
    setError (state, error) {
      state.error = error
    },
    clearError (state) {
      state.error = null
    },
    logout (state) {
      state.auth.status.loggedIn = false
      state.auth.user = null
    }
  },
  modules: {
    auth,
    themeLayout,
    template,
  },
});
