import { varribles } from './varribles'
const user = JSON.parse(localStorage.getItem('user'))

export const auth = {
    namespaced: true,
    state: user
        ? {
            status: {
                loggegIn: true
            },
            user
        }
        : {
            status: {
                loggedIn: false
            },
            user: null
        },
    getters: {
        userGetter: (state) => state.user
    },
    actions: {
        async login ({ commit }, { mail, password }) {
            const body = { mail, password }
            try {
                const response = await fetch(varribles.apiUrl + '/signin', {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                if (!response.ok) {
                    commit('setError', response.status, { root: true })
                    throw response.status
                }
                const result = await response.json()
                if (result.token) localStorage.setItem('user', JSON.stringify(result))
                return commit('loginSuccess', result)
            } catch (error) {
                commit('setError', error, { root: true })
                throw error
            }
        },
        async logout ({ commit }) {
            localStorage.removeItem('user')
            commit('logout', null, { root: true })
        }
    },
    mutations: {
        loginSuccess (state, user) {
            state.status.loggedIn = true
            state.user = user
        },
        loginFailure (state) {
            state.status.loggedIn = false
            state.user = null
        }
    }
}