import { varribles } from './varribles'

export const template = {
    namespaced: true,
    actions: {
        // eslint-disable-next-line no-unused-vars
        async fetchMethod ({ commit }, {
            url,
            method,
            body,
            content_type,
        }) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (!user) throw 'Отсутсвует Токен'
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': content_type,
                    Authorization: 'Bearer ' + user.token
                }
                if (content_type === null) delete headers['Content-Type']
                const response = await fetch(varribles.apiUrl + url, {
                    method,
                    body,
                    headers
                })
                return await response.json()
            } catch(e) {
                console.log(e)
                throw 'Ошибка выполнения запроса'
            }
        },
        // eslint-disable-next-line no-unused-vars
        async fetchMethodPublic ({ commit }, {
            url,
            method,
            body,
            content_type,
        }) {
            try {
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': content_type
                }
                const response = await fetch(varribles.apiUrl + url, {
                    method,
                    body,
                    headers
                })
                return await response.json()
            } catch(e) {
                console.log(e)
                throw 'Ошибка выполнения запроса'
            }
        }
    }
}
