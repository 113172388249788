import { createWebHistory, createRouter } from "vue-router";
import store from '@/vuex/store';


const router = new createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes: [
    {
      name: "Auth",
      path: "/auth",
      component: () => import("@/layout/withAuthLayout.vue"),
      children: [
        {
          path: "login",
          name: "login",
          component: () => import("@/view/authentication/SignIn.vue"),
        },
      ],
      meta: { auth: false },
    },
    {
      name: "Public",
      path: "/",
      component: () => import("@/layout/withUserLayout.vue"),
      children: [
        {
          path: "",
          name: "dashboard",
          component: () => import("@/view/projects/Dashboard.vue"),
        },
        {
          path: "project",
          name: "project",
          component: () => import("@/view/projects/Project.vue"),
        },
        // {
        //   path: "news",
        //   name: "news",
        //   component: () => import("@/view/news/News.vue"),
        // },
        {
          path: "/search",
          name: "search",
          component: () => import("@/view/projects/Search.vue"),
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/view/help/FAQ.vue"),
        },
        {
          path: "/rules",
          name: "rules",
          component: () => import("@/view/help/Rules.vue"),
        },
        {
          path: "/contacts",
          name: "contacts",
          component: () => import("@/view/help/Contacts.vue"),
        },
      ],
      meta: { auth: false },
    },
    {
      name: "Private",
      path: "/user",
      component: () => import("@/layout/withUserLayout.vue"),
      children: [
        {
          path: "profile",
          name: "profile",
          component: () => import("@/view/users/Profile.vue"),
        },
        {
          path: "projects",
          name: "UserProjects",
          component: () => import("@/view/users/Projects.vue"),
        },
      ],
      meta: { auth: true }
    },
    {
      name: "Admin",
      path: "/admin",
      component: () => import("@/layout/withUserLayout.vue"),
      children: [
        {
          path: "users",
          name: "AdminUsers",
          component: () => import("@/view/admin/Users.vue"),
        },
        {
          path: "projects",
          name: "AdminProjects",
          component: () => import("@/view/admin/Projects.vue"),
        },
        {
          path: "news",
          name: "AdminNews",
          component: () => import("@/view/admin/News.vue"),
        },
      ],
      meta: { auth: true }
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  const authRequired = to.matched.some((record) => record.meta.auth)
  const loggedIn = localStorage.getItem('user')
  if (to.name === 'login' && loggedIn) {
    next({ name: 'dashboard' })
  } else if (authRequired && !loggedIn) {
    next({ name: 'login' })
  } else {
    if (authRequired) {
      const user = await store.dispatch("template/fetchMethod", {
        url: "/user/info",
        method: "GET",
        body: null,
        content_type: "application/json",
      })
      if (user.success) {
        next()
      } else {
        localStorage.removeItem('user')
        next({ name: 'login' })
      }
    } else {
      next()
    }
  }


  //   const authRequired = to.matched.some((record) => record.meta.auth);
  //   const loggedIn = localStorage.getItem("user");
  //   if (!authRequired && loggedIn) {
  //     next({ name: "home" });
  //   }
  //   if (authRequired && !loggedIn) {
  //     next({ name: "login", params: { message: "login" } });
  //   } else next();

  // console.log(to, store);
  // if (to.meta.auth && store.state.auth.login) {
  //   next({ to: '/' });
  // } else if (!to.meta.auth && !store.state.auth.login) {
  //   next({ name: 'login' });
  // } else {
  //   next();
  // }
});

export default router;
