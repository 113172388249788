export default {
  lightMode: {
    'main-background': '#DFDFDF',
    'main-background-light': '#DFDFDF',
		'main-background-light-dark': '#DFDFDF',
    'dark-background': '#EFF0F3',
		'dark-background2': '#0A0A0A',
    'darker-background': '#202444',
    'darker-background2': '#ffffff',
    'white-background': '#FFFFFF',
    'general-background': '#DFDFDF',
    'brand-background': '#FFFFFF',
    'status-background': '#F8F9FB',
    'primary-trasparent': '#FFEAF3',
    'light-background': '#F8F9FB',
    'light-border': '#F1F2F6',
    'white-text': '#ffffff',
    'gray-text': '#404040',
    'gray-lighter-text': '#404040',
    'gray-light-text': '#8C90A4',
    'dark-text': '#0A0A0A',
    'light-text': '#8C90A4',
    'extra-light-text': '#ADB4D2',
    'light-gray-text': '#404040',
    'extra-light': '#ADB5D9',
    'border-color-default': '#F1F2F6',
    'border-color-checkbox': '#C6D0DC',
    'border-color-secondary': '#E3E6EF',
    'border-color-deep': '#C6D0DC',
    'menu-icon-color': '#ADB5D9',
    'menu-active': '#000',
    'menu-active-bg': 'rgba(130, 49, 211, 0.1)',
    'scroll-bg': '#E3E6EF',
    'chat-bg': '#EFF0F3',
    'chat-reply-bg': '#5840FF',
    'chat-reply-text': '#ffffff',
    'input-bg': '#ffffff',
    'primary-white': '#8231D3',
		'secondary-white': '#5840FF',
    'badge-example-bg': '#ddd',
    'primary-white-15': '#48382B',
  },
  blackMode: {
    'main-background': '#F8F9FB',
    'main-background-light': '#F8F9FB',
		'main-background-light-dark': '#f4f5f7',
    'dark-background': '#EFF0F3',
		'dark-background2': '#0A0A0A',
    'darker-background': '#202444',
    'darker-background2': '#ffffff',
    'white-background': '#FFFFFF',
    'general-background': '#F4F5F7',
    'brand-background': '#FFFFFF',
    'status-background': '#F8F9FB',
    'primary-trasparent': '#FFEAF3',
    'light-background': '#F8F9FB',
    'light-border': '#F1F2F6',
    'white-text': '#ffffff',
    'gray-text': '#404040',
    'gray-lighter-text': '#404040',
    'gray-light-text': '#8C90A4',
    'dark-text': '#0A0A0A',
    'light-text': '#8C90A4',
    'extra-light-text': '#ADB4D2',
    'light-gray-text': '#404040',
    'extra-light': '#ADB5D9',
    'border-color-default': '#F1F2F6',
    'border-color-checkbox': '#C6D0DC',
    'border-color-secondary': '#E3E6EF',
    'border-color-deep': '#C6D0DC',
    'menu-icon-color': '#ADB5D9',
    'menu-active': '#8231D3',
    'menu-active-bg': 'rgba(130, 49, 211, 0.1)',
    'scroll-bg': '#E3E6EF',
    'chat-bg': '#EFF0F3',
    'chat-reply-bg': '#5840FF',
    'chat-reply-text': '#ffffff',
    'input-bg': '#ffffff',
    'primary-white': '#8231D3',
		'secondary-white': '#5840FF',
    'badge-example-bg': '#ddd',
    'primary-white-15': '#8231D315',
  }
};
